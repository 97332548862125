import { useMemo } from 'react';

import QueryStatsIcon from '@mui/icons-material/QueryStats';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';

import AuthAPI from 'src/api/auth';
import DBIcon from 'src/assets/icons/db';
import CloudIcon from 'src/assets/icons/cloud';
import DangerIcon from 'src/assets/icons/danger';

//

import { useQuery } from '@tanstack/react-query';

import { paths } from 'src/routes/paths';

// ---------------------------------------------

const ICONS = {
  storage: <DBIcon />,
  intel: <CloudIcon />,
  risk: <DangerIcon />,
  hub: <HubOutlinedIcon />,
  query: <QueryStatsIcon />,
  doc: <AssessmentOutlinedIcon />,
  analytics: <TrendingUpOutlinedIcon />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { data: userProfile } = useQuery({
    queryKey: ['get_user_profile'],
    queryFn: () => AuthAPI.userProfile(),
  });
  const data = useMemo(() => {
    const productHubNav = {
      title: 'Product Hub',
      path: `${paths.product_hub.root}`,
      icon: ICONS.hub,
    };

    const routes = [
      {
        subheader: 'Menu',
        items: [
          {
            title: 'Smart Viz and Analytics',
            path: `${paths.dashboard.root}`,
            icon: ICONS.query,
          },
          {
            title: 'IntelliRisk™',
            path: paths.risk.root,
            icon: ICONS.risk,
            customMsg: 'Coming Soon',
          },
          {
            title: 'Nonconformance Analytics',
            path: `#`,
            icon: ICONS.analytics,
            customMsg: 'Coming Soon',
          },
          {
            title: 'Competitive Intelligence',
            path: `#`,
            icon: ICONS.intel,
            customMsg: 'Coming Soon',
          },
          {
            title: 'Insights and Reports',
            path: `#`,
            icon: ICONS.doc,
            customMsg: 'Coming Soon',
          },
          {
            title: 'Data Sources',
            path: `#`,
            icon: ICONS.storage,
            customMsg: 'Coming Soon',
          },
        ],
      },
    ];
    if (
      userProfile?.email.endsWith('empowerreg.ai') ||
      userProfile?.email.startsWith('mukeshsoni')
    ) {
      routes[0].items.unshift(productHubNav);
    }
    return routes;
  }, [userProfile]);

  return data;
}
